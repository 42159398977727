/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components), {Type, Params} = _components;
  if (!Params) _missingMdxReference("Params", true);
  if (!Type) _missingMdxReference("Type", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "概要",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%A6%82%E8%A6%81",
    "aria-label": "概要 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "概要"), "\n", React.createElement(_components.p, null, "Moveコンポーネントは、リスト要素のにスタイルを適用することで、スライダーを実際に動かします。トランジションコンポーネントは、このコンポーネントが呼び出します。"), "\n", React.createElement(_components.h2, {
    id: "メソッド",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E3%83%A1%E3%82%BD%E3%83%83%E3%83%89",
    "aria-label": "メソッド permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), "メソッド"), "\n", React.createElement(_components.h3, {
    id: "move",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#move",
    "aria-label": "move permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "move()")), "\n", React.createElement(Type, {
    code: 'move( dest: number, index: number, prev: number ): void'
  }), "\n", React.createElement(_components.p, null, "与えられた", React.createElement(_components.code, null, "dest"), "インデックスに向かい、トランジションコンポーネントを用いてスライダーを動かします。このメソッドはインデックスの更新などを行いませんので、基本的には直接使用せず、", React.createElement(_components.code, null, "Controller#go"), "を利用してください。"), "\n", React.createElement(Params, {
    params: [['dest', '目的地となるインデックスで、クローンのインデックスも受け付ける'], ['index', '目的地となるスライドのインデックス。ループモード以外では`dest`と一致する'], ['prev', '移動前の現在のインデックス']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "jump",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#jump",
    "aria-label": "jump permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "jump()")), "\n", React.createElement(Type, {
    code: 'jump( index: number ): void'
  }), "\n", React.createElement(_components.p, null, "トランジションなしで、目的のインデックスに直接移動します。"), "\n", React.createElement(Params, {
    params: [['index', '移動先のインデックス']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "translate",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#translate",
    "aria-label": "translate permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "translate()")), "\n", React.createElement(Type, {
    code: 'translate( position: number ): void'
  }), "\n", React.createElement(_components.p, null, "与えられたポジションにスライダーを移動させます。"), "\n", React.createElement(Params, {
    params: [['position', '目的地をピクセルで指定']]
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "cancel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cancel",
    "aria-label": "cancel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "cancel()")), "\n", React.createElement(Type, {
    code: 'cancel(): void'
  }), "\n", React.createElement(_components.p, null, "トランジションを中断します。"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "toindex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#toindex",
    "aria-label": "toindex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "toIndex()")), "\n", React.createElement(Type, {
    code: 'toIndex( position: number ): number'
  }), "\n", React.createElement(_components.p, null, "与えられたポジションに最も近いスライドのインデックスを返します。"), "\n", React.createElement(Params, {
    params: [['position', '変換するポジション']],
    ret: '与えられたポジションに最も近いスライドのインデックス'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "toposition",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#toposition",
    "aria-label": "toposition permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "toPosition()")), "\n", React.createElement(Type, {
    code: 'toPosition( index: number, trimming?: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "与えられたインデックスをポジションに変換します。"), "\n", React.createElement(Params, {
    params: [['index', '変換するインデックス'], ['trimming', '省略可。両端の余白を取り除いた結果を返すかどうかを決める']],
    ret: '与えられたインデックスが対応するポジション'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getposition",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getposition",
    "aria-label": "getposition permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getPosition()")), "\n", React.createElement(Type, {
    code: 'getPosition(): number'
  }), "\n", React.createElement(_components.p, null, "現在のポジションを取得します。"), "\n", React.createElement(Params, {
    ret: 'リスト要素の現在の位置をピクセルで返す'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "getlimit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#getlimit",
    "aria-label": "getlimit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "getLimit()")), "\n", React.createElement(Type, {
    code: 'getLimit( max: boolean ): number'
  }), "\n", React.createElement(_components.p, null, "スライダーが移動できる限界値を返します。"), "\n", React.createElement(Params, {
    params: [['max', '最大値を返すか、あるいは最小値を返すかを決める']],
    ret: '限界の値をピクセルで返す'
  }), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h3, {
    id: "exceededlimit",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#exceededlimit",
    "aria-label": "exceededlimit permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg viewBox=\"0 0 40 40\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"m12.4 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m26 0.842-4.44 38.3h6l4.44-38.3z\"/><path d=\"m4.23 23.8-0.695 6h30.7l0.695-6z\"/><path d=\"m5.8 10.2-0.695 6h30.7l0.695-6z\"/></svg>"
    }
  })), React.createElement(_components.code, null, "exceededLimit()")), "\n", React.createElement(Type, {
    code: 'exceededLimit( max?: boolean | undefined, position?: number ): boolean'
  }), "\n", React.createElement(_components.p, null, "スライダーが限界値を超えているかどうかを判定します。"), "\n", React.createElement(Params, {
    params: [['max', '省略可。`true`の場合は最大値を、`false`の場合は最小値を、`undefined`の場合はその両方をテストする'], ['position', '省略可。テスト対象となるポジションを明示的に指定する。省略した場合、現在地が使われる']],
    ret: '限界値を超えている場合は`true`を、そうでない場合は`false`を返す'
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
